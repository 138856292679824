export default (state, action) => {
    switch (action.type) {
        case 'GET_CLIENT_LIST':
            return {
                ...state,
                loading: false,
                client_list: action.payload,
                sales_branch: action.sales_branch,
                distributor: action.distributor,
                next: action.next,
                previous: action.previous,
                count: action.count,
                action: action.actions
            }

        case 'VIEW_CLIENT':
            return {
                ...state,
                loading: false,
                editData: action.payload,
                sales_branch: action.sales_branch,
                distributor: action.distributor
            }

        case 'SORT_TABLE':
            return {
                ...state,
                loading: false,
                client_list: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'CHANGE_TABLE_NUM_ROWS':
            return {
                ...state,
                loading: false,
                client_list: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'SET_TABLE_PAGE_NO':
            return {
                ...state,
                loading: false,
                client_list: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'QUERY_TABLE_DATA':
            return {
                ...state,
                query: action.query,
                client_list: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'ADD_CLIENT':
            return {
                ...state,
                loading: false,
                client_list: [...state.client_list , action.payload],
                success: action.success,
                error: action.error
            }

        case 'DELETE_CLIENT':
            return {
                ...state,
                client_list: state.client_list.filter(cl => cl[0] !== action.payload)
            }

        case 'UPDATE_CLIENT':
            return {
                ...state,
                loading: false,
                client_list: [...state.client_list , action.payload],
            }

        case 'CLIENT_ERROR':
            return {
                ...state,
                error: action.payload
            }

        case 'CLEAR_CLIENT_ERROR':
            return {
                ...state,
                error: []
            }

        default:
            return state;
    }
}