import React from 'react';
import * as Constants from '../Constants';
import { toast } from 'react-toastify';

toast.configure();

const AuthContext = React.createContext();

class AuthProvider extends React.Component {

    state = {
        isAuth: this.checkAuth(),
        isAdmin: this.isAdmin(),
        userType: this.getUserType(),
        route: this.getRoute(),
        username: '',
        password: '',
        message: '',
        error: false
    }

    constructor() {
        super();

        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onToggleError = this.onToggleError.bind(this);
        this.login = this.login.bind(this);
        this.getRoute = this.getRoute.bind(this);
        this.logout = this.logout.bind(this);
        this.checkAuth = this.checkAuth.bind(this);
        this.isAdmin = this.isAdmin.bind(this);
        this.getUserType = this.getUserType.bind(this);
    }

    checkAuth() {
        return localStorage.getItem('auth');
    }

    isAdmin() {
        if (JSON.parse(localStorage.getItem('auth'))) {
            return JSON.parse(localStorage.getItem('auth')).is_admin === true || JSON.parse(localStorage.getItem('auth')).user_type === 'admin';
        }

        return false;
    }

    getUserType() {
        if (JSON.parse(localStorage.getItem('auth'))) {
            return JSON.parse(localStorage.getItem('auth')).user_type;
        }
    }

    onChangeUsername(username) {
        this.setState({
            username: username
        });
    }

    onChangePassword(password) {
        this.setState({
            password: password
        });
    }

    onToggleError() {
        this.setState({
            error: !this.state.error
        })
    }

    login(e) {
        e.preventDefault();

        Constants.API_BASE_URL.post(
            Constants.AUTH_LOGIN_URL,
            {
                username: this.state.username,
                password: this.state.password
            },
            { headers: { 'Accept-Language': 'ja' } }
        )
            .then(res => {
                if (res.data.details.code === 0) {
                    localStorage.setItem('auth', JSON.stringify({
                        id: res.data.body.data.id,
                        token: `Token ${res.data.body.data.token}`,
                        username: res.data.body.data.username,
                        email: res.data.body.data.email,
                        is_admin: res.data.body.data.is_admin,
                        user_type: res.data.body.data.user_type,
                        groups: res.data.body.data.groups,
                        user_permissions: res.data.body.data.user_permissions
                    }));

                    window.location.reload();
                }
            })
            .catch(ex => {
                if (ex.response.status === 422) { // (Unprocessable Entity)
                    this.setState({
                        message: 'メールアドレス、パスワードを入力してください。'
                    });
                }

                if (ex.response.status === 401) { // (Unauthorized)
                    this.setState({
                        message: ex.response.data.details.message
                    });
                }

                this.setState({
                    error: true
                });
            });
    }

    logout() {
        try {
            Constants.API_BASE_URL.get(
                Constants.AUTH_LOGOUT_URL,
                {
                    headers: {
                        'Accept-Language': 'ja',
                        'Authorization': JSON.parse(localStorage.getItem('auth')).token
                    }
                }
            ).then(() => {
                localStorage.clear();
                this.setState({
                    isAuth: false
                });
            }).catch(ex => {
                toast.error(ex.response.data.details.message, { position: toast.POSITION.TOP_CENTER });
            });
        } catch (error) {
            localStorage.clear();
            this.setState({
                isAuth: false
            });
        }
    }

    getRoute() {
        return '/facility';
    }

    render() {
        return (
            <AuthContext.Provider
                value={{
                    isAuth: this.state.isAuth,
                    isAdmin: this.state.isAdmin,
                    userType: this.state.userType,
                    route: this.state.route,
                    onChangeUsername: this.onChangeUsername,
                    onChangePassword: this.onChangePassword,
                    onToggleError: this.onToggleError,
                    login: this.login,
                    logout: this.logout,
                    message: this.state.message,
                    error: this.state.error
                }}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer }