export default (state, action) => {
    switch (action.type) {
        case 'GET_FACILITY_DEPARTMENT_LIST':
            return {
                ...state,
                loading: false,
                fac_dept: action.payload,
                client: action.client,
                sales_branch: action.sales_branch,
                distributor: action.distributor,
                next: action.next,
                previous: action.previous,
                count: action.count,
                action: action.actions
            }

        case 'VIEW_FACILITY_DEPARTMENT':
            return {
                ...state,
                loading: false,
                editData: action.payload
            }

        case 'SORT_TABLE':
            return {
                ...state,
                loading: false,
                fac_dept: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'CHANGE_TABLE_NUM_ROWS':
            return {
                ...state,
                loading: false,
                fac_dept: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'SET_TABLE_PAGE_NO':
            return {
                ...state,
                loading: false,
                fac_dept: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'QUERY_TABLE_DATA':
            return {
                ...state,
                query: action.query,
                fac_dept: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'ADD_FACILITY_DEPARTMENT':
            return {
                ...state,
                loading: false,
                fac_dept: [...state.fac_dept , action.payload],
                success: action.success,
                error: action.error
            }

        case 'DELETE_FACILITY_DEPARTMENT':
            return {
                ...state,
                fac_dept: state.fac_dept.filter(fd => fd[0] !== action.payload)
            }

        case 'UPDATE_FACILITY_DEPARTMENT':
            return {
                ...state,
                loading: false,
                fac_dept: [...state.fac_dept , action.payload],
            }

        case 'FACILITY_DEPARTMENT_ERROR':
            return {
                ...state,
                error: { shiftType: action.payload.shift, ...action.payload}
            }

        case 'CLEAR_FACILITY_DEPARTMENT_ERROR':
            return {
                ...state,
                error: []
            }

        default:
            return state;
    }
}