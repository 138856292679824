import { toast } from 'react-toastify';
import { API_BASE_URL } from '../Constants';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const ROUTE_EXCEPTIONS = [
    '/user/password-reset-confirm/',
    '/auth/login/'
];

const exception = (responseURL) => {
    let found = false;

    ROUTE_EXCEPTIONS.map(url => {
        if(responseURL.includes(url)) {
            found = true;
        }

        return 0;
    });

    return found;
}

API_BASE_URL.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        const errMsg = err.response.data.details.message;

        switch (err.response.status) {
            case 401:
                if(!exception(err.response.config.url)) {
                    localStorage.clear();
                    window.location.reload();
                }
                break;

            case 403:
                window.location.href = '/Forbidden';
                break;

            case 404:
                toast.error(errMsg, { position: toast.POSITION.TOP_CENTER });
                break;

            default:
                break;
        }

        return Promise.reject(err);
    }
);