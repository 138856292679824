import React, { Suspense, lazy } from 'react';
import './App.css';

/* ROUTING IMPORTS */
import ProtectedRoute from './ProtectedRoute';
import { CircularProgress } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/* CONTEXT PROVIDERS */
import { AuthProvider } from './context/AuthContext';
import { ClientProvider } from './context/client/ClientContext';
import { FacilityDeptProvider } from './context/facility/FacilityDeptContext';

/* LAZY LOADED COMPONENTS */
const UserMenu = lazy(() => import('./components/UserMenu'));
const Login = lazy(() => import('./components/Login'));
const PageNotFound = lazy(() => import('./components/PageNotFound'));
const Forbidden = lazy(() => import('./components/Forbidden'));
const FacilityDepartment = lazy(() => import('./components/facility/FacilityDepartment'));
const EditFacility = lazy(() => import('./components/facility/EditFacility'));

const loading = () => {
    return (
        <CircularProgress
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%'
            }} />
    );
}

function App() {
    return (
        <React.Fragment>
            <Router>
                <AuthProvider>
                    <Suspense fallback={loading()}>
                        <UserMenu />
                        <Switch>
                            <Route exact path='/' component={Login} />
                            <ProtectedRoute path='/NotFound' component={PageNotFound} />
                            <ProtectedRoute path='/Forbidden' component={Forbidden} />
                        </Switch>
                    </Suspense>
                    <Suspense fallback={loading()}>
                        <FacilityDeptProvider>
                            <ClientProvider>
                                <Switch>
                                    {/*
                                    Super admin
                                    */}
                                    <ProtectedRoute exact path='/facility/:id' component={FacilityDepartment} />
                                    <ProtectedRoute exact path='/facility/' component={FacilityDepartment} />
                                    <ProtectedRoute exact path='/facility/editFacility/:id' component={EditFacility} />
                                    <ProtectedRoute exact path='/facility/showFacility/:id' render={routeProps => <EditFacility mode="show" {...routeProps} />} />
                                </Switch>
                            </ClientProvider>
                        </FacilityDeptProvider>
                    </Suspense>
                </AuthProvider>
            </Router>
        </React.Fragment>
    );
}

export default App;