import React, { createContext, useReducer, useState } from 'react';
import FacilityDepartmentReducer from './FacilityDepartmentReducer';
import * as Constants from '../../Constants';

const initialState = {
    fac_dept: [],
    distributor: {},
    sales_branch: {},
    client: {},
    editData: [],
    loading: true,
    next: '',
    previous: null,
    count: 0,
    page: 0,
    rowsPerPage: 10,
    query: '',
    sortName: 'facility__client__name_kana',
    sortOrder: 'asc',
    success: false,
    action: [{
        view: false,
        create: false,
        delete: false,
        update: false,
        management: false
    }],
    error: [{
        name: null,
        name_kana: null,
        postal_code: null,
        address: null,
        telephone_number: null,
        shift: null,
        shiftType: null,
        e_unit_cost: null,
        aqp_unit_cost: null,
        facility: null
    }]
};

export const FacilityDeptContext = createContext(initialState);

export const FacilityDeptProvider = ({ children }) => {

    const [clientName, setClientName] = useState('');
    const [facilityName, setFacilityName] = useState('');
    const [kanaName, setKanaName] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [operationType, setOperationType] = useState('');
    const [eUnitCost, setEUnitCost] = useState('');
    const [aqpUnitCost, setAqpUnitCost] = useState('');
    const [remark, setRemark] = useState('');
    const [powerDonorLinked, setPowerDonorLinked] = useState(false);
    const [path, setPath] = useState(null);

    // map_info
    const [timeFrom, setTimeFrom] = useState('');
    const [timeTo, setTimeTo] = useState('');
    const [multRate, setMultRate] = useState('');
    const [contactNum, setContactNum] = useState('');
    const [locDesc, setLocDesc] = useState('');
    const [otherInfo, setOtherInfo] = useState('');
    const [remainingPower, setRemainingPower] = useState('');
    const [calculatedRemainingPower, setCalculatedRemainingPower] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [remarks, setRemarks] = useState('');

    const [state, dispatch] = useReducer(FacilityDepartmentReducer, initialState);

    const config = {
        headers: {
            'Accept-Language': 'ja',
            'Authorization': localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).token
        }
    };

    const granular_permissions = localStorage.getItem('auth') === null ? [] : JSON.parse(localStorage.getItem('auth')).user_permissions;

    // User permissions for crud operations
    const actions = {
        view: can('facility.view_facility'),
        create: can('facility.add_facility'),
        delete: can('facility.delete_facility'),
        update: can('facility.change_facility'),
        management: canManageUsers(),
    }

    async function sortData(name, direction) {
        let api_url = `${Constants.GET_FAC_DEPT_URL}?page=${state.page + 1}&order_by=${direction === 'desc' ? '-' : ''}${name}&page_size=${state.rowsPerPage}${state.client ? `&client=${state.client.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'SORT_TABLE',
                payload: res.data.body.data.results.map((fd, index) =>
                    [fd.facility.id, index + 1, fd.facility.client.name, fd.facility.name, fd.power_outage]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                sortName: name,
                sortOrder: direction
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: 'FACILITY_DEPARTMENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changeNumberOfRows(rows) {
        let api_url = `${Constants.GET_FAC_DEPT_URL}?page=1&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${rows}${state.client ? `&client=${state.client.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'CHANGE_TABLE_NUM_ROWS',
                payload: res.data.body.data.results.map((fd, index) =>
                    [fd.facility.id, index + 1, fd.facility.client.name, fd.facility.name, fd.power_outage]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0,
                rowsPerPage: rows
            });

        } catch (error) {
            dispatch({
                type: 'FACILITY_DEPARTMENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changePageNumber(tblPage) {
        let url = null;
        let nextPage = 0;

        if (tblPage > state.page) {
            url = state.next;
        } else {
            url = state.previous;
        }

        if (url !== null) {
            let urlExplode = new URL(url);
            nextPage = urlExplode.searchParams.get('page');
            nextPage = nextPage === null ? 1 : nextPage;
        }

        try {
            let api_url = `${Constants.GET_FAC_DEPT_URL}?page=${nextPage}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${state.client ? `&client=${state.client.id}` : ''}`;
            api_url += state.query !== '' ? `&search=${state.query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'SET_TABLE_PAGE_NO',
                payload: res.data.body.data.results.map((fd, index) =>
                    [fd.facility.id, index + 1, fd.facility.client.name, fd.facility.name, fd.power_outage]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: tblPage
            });

        } catch (error) {
            dispatch({
                type: 'FACILITY_DEPARTMENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function queryData(query = '') {
        let api_url = `${Constants.GET_FAC_DEPT_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${state.client ? `&client=${state.client.id}` : ''}`;

        try {
            api_url += query !== '' ? `&search=${query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'QUERY_TABLE_DATA',
                query: query,
                payload: res.data.body.data.results.map((fd, index) =>
                    [fd.facility.id, index + 1, fd.facility.client.name, fd.facility.name, fd.power_outage]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });

        } catch (error) {
            dispatch({
                type: 'FACILITY_DEPARTMENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function getFacilities(id = null) {
        let api_url = `${Constants.GET_FAC_DEPT_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${id ? `&client=${id}` : ''}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                ...{ actions: actions },
                type: 'GET_FACILITY_DEPARTMENT_LIST',
                payload: res.data.body.data.results.map((fd, index) =>
                    [fd.facility.id, index + 1, fd.facility.client.name, fd.facility.name, fd.power_outage]),
                client: res.data.body.data.client,
                sales_branch: res.data.body.data.sales_branch,
                distributor: res.data.body.data.distributor,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: 'FACILITY_DEPARTMENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function toggleActivate(record) {
        try {
            console.log(record);
            const res = await Constants.API_BASE_URL.patch(
                Constants.TOGGLE_POWER_OUTAGE.replace(/{id}/, record.id), {}, config)

            dispatch({
                type: 'DELETE_FACILITY_DEPARTMENT',
                payload: record.id
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'FACILITY_DEPARTMENT_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function updateFacility(record) {
        try {
            const res = await Constants.API_BASE_URL.patch(Constants.PATCH_MAP_INFO_URL.replace(/{id}/, record.id), record, config);

            dispatch({
                type: 'UPDATE_FACILITY_DEPARTMENT',
                payload: record,
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'FACILITY_DEPARTMENT_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function viewFacility(id) {
        let api_url = Constants.GET_MAP_INFO_URL.replace(/{id}/, id);

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const data = res.data.body.data;

            setClientName(data.client_name);
            setFacilityName(data.facility.name);
            setKanaName(data.facility.name_kana);
            setPostalCode(data.facility.postal_code);
            setAddress(data.facility.address);
            setPhoneNumber(data.facility.telephone_number);
            setOperationType(data.facility.shift);
            setEUnitCost(data.facility.e_unit_cost);
            setAqpUnitCost(data.facility.aqp_unit_cost);
            setRemark(data.facility.remarks);
            setPowerDonorLinked(data.facility.power_donor_linked);

            setTimeFrom(data.time_from);
            setTimeTo(data.time_to);
            setMultRate(data.mult_rate);
            setContactNum(data.contact_num);
            setLocDesc(data.loc_desc);
            setOtherInfo(data.other_info);
            setRemainingPower(data.remaining_power);
            setCalculatedRemainingPower(data.calculated_remaining_power);
            setYoutubeUrl(data.youtube_url);
            setLatitude(data.latitude);
            setLongitude(data.longitude);
            setRemarks(data.remarks);

            dispatch({
                type: 'VIEW_FACILITY_DEPARTMENT',
                payload: data
            });

        } catch (error) {
            dispatch({
                type: 'FACILITY_DEPARTMENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    function clearErrors() {
        dispatch({
            type: 'CLEAR_FACILITY_DEPARTMENT_ERROR'
        });
    }

    // Create path generator base on user type if super admin, distributor admin, distributor staff, sales branch or client
    function generatePath() {
        let path = null;
        const user_type = localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).user_type;

        switch (user_type) {
            case 'admin':
                /*
                 * /distributor/sales_branch/client/facility/addFacility
                 * /distributor/sales_branch/client/facility/editFacility/:id
                 * /distributor/sales_branch/client/facility/Facility/:id
                 * /distributor/sales_branch/client/facility/:id
                 **/
                path = '/distributor/sales_branch/client/facility';
                break;

            case 'distributor':
                /*
                 * /sales_branch/client/facility/addFacility
                 * /sales_branch/client/facility/editFacility/:id
                 * /sales_branch/client/facility/showFacility/:id
                 * /sales_branch/client/facility/:id
                 **/
                path = '/sales_branch/client/facility';
                break;

            case 'sales_branch':
                /*
                 * /client/facility/:id
                 * /client/facility/showFacility/:id
                **/
                path = '/client/facility';
                break;

            case 'client':
                /*
                  * /facility/
                  * /facility/showFacility/:id
                  */
                path = '/facility';
                break;

            default:
                path = '/';
                break;
        }

        setPath(path);
    }

    function resetLocalStorage() {
        localStorage.clear();
        window.location.reload();
    }

    function can(action) {
        if (granular_permissions) {
            return granular_permissions.includes(action);
        }

        resetLocalStorage();
    }

    function canManageUsers() {
        if (granular_permissions) {
            return granular_permissions.includes('users.view_user')
                && granular_permissions.includes('users.add_user')
                && granular_permissions.includes('users.change_user')
                && granular_permissions.includes('users.delete_user');
        }

        resetLocalStorage();
    }

    return (
        <FacilityDeptContext.Provider value={{
            fac_dept: state.fac_dept,
            editData: state.editData,
            loading: state.loading,
            distributor: state.distributor,
            sales_branch: state.sales_branch,
            client: state.client,
            links: state.links,
            count: state.count,
            rowsPerPage: state.rowsPerPage,
            page: state.page,
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            error: state.error,
            action: state.action,
            clientName, setClientName,
            facilityName, setFacilityName,
            kanaName, setKanaName,
            postalCode, setPostalCode,
            address, setAddress,
            phoneNumber, setPhoneNumber,
            operationType, setOperationType,
            eUnitCost, setEUnitCost,
            aqpUnitCost, setAqpUnitCost,
            remark, setRemark,
            powerDonorLinked, setPowerDonorLinked,
            timeFrom, setTimeFrom,
            timeTo, setTimeTo,
            multRate, setMultRate,
            contactNum, setContactNum,
            locDesc, setLocDesc,
            otherInfo, setOtherInfo,
            remainingPower, setRemainingPower,
            calculatedRemainingPower,
            youtubeUrl, setYoutubeUrl,
            latitude, setLatitude,
            longitude, setLongitude,
            remarks, setRemarks,
            path, generatePath,
            sortData,
            queryData,
            changeNumberOfRows,
            changePageNumber,
            toggleActivate,
            viewFacility,
            updateFacility,
            getFacilities,
            clearErrors
        }}>
            {children}
        </FacilityDeptContext.Provider>
    );
};